import { Icon } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ApiBasicsSvg from '../../assets/svgs/api-basics.svg'
import DesktopSvg from '../../assets/svgs/collaboration-build-320.svg'
import IntegrationsSvg from '../../assets/svgs/integrations.svg'
import GettingStartedCard from '../../components/GettingStartedCard'
import Link from '../../components/Link'
import Seo from '../../components/Seo'
import TableOfContents from '../../components/TableOfContents'
import { PAGE_VIEWED } from '../../constants/metrics'
import DocumentationLayout from '../../layouts/DocumentationLayout'
import MetricsService from '../../services/metricsService'

const GettingStarted: React.FC = (): JSX.Element => {
  const metricsService = MetricsService.getInstance()
  const { t } = useTranslation()
  const pageName = 'Getting Started'

  metricsService.track(PAGE_VIEWED, {
    pageName: pageName,
  })

  const tableOfContentsItems = [
    {
      label: t('gettingStarted.whatCanYouDo'),
      level: '2',
      href: 'whatCanYouDoHere',
    },
    {
      label: t('gettingStarted.samples'),
      level: '2',
      href: 'samples',
    },
    {
      label: t('gettingStarted.needHelp'),
      level: '2',
      href: 'needHelp',
    },
  ]

  const cards = [
    {
      link: '/documentation/introduction-to-apis',
      buttonText: t('gettingStarted.seeMore'),
      title: t('gettingStarted.apiBasics'),
      image: <ApiBasicsSvg />,
    },
    {
      link: '/documentation/guides/desktop',
      buttonText: t('gettingStarted.seeMore'),
      title: t('gettingStarted.customDesktop'),
      image: <DesktopSvg />,
    },
    {
      link: '/documentation/integrations',
      buttonText: t('gettingStarted.seeMore'),
      title: t('gettingStarted.integrations'),
      image: <IntegrationsSvg />,
    },
  ]

  return (
    <DocumentationLayout>
      <Seo title={pageName} />
      <div className="guide-container with-toc">
        <article className="content">
          <section>
            <h1>{t('gettingStarted.gettingStarted')}</h1>
            <p>{t('gettingStarted.intro')}</p>
          </section>

          <section>
            <h2 id="whatCanYouDoHere">{t('gettingStarted.whatCanYouDo')}</h2>
            <p>{t('gettingStarted.whatCanYouDoP1')}</p>
            <div className="getting-started-card-container">
              {cards.map((card, i) => {
                return (
                  <GettingStartedCard
                    image={card.image}
                    title={card.title}
                    buttonText={card.buttonText}
                    link={card.link}
                    key={i}
                  />
                )
              })}
            </div>
          </section>

          <section>
            <h2 id="samples">{t('gettingStarted.samples')}</h2>
            <p>{t('gettingStarted.samplesText')}</p>
            <Link
              to="https://github.com/CiscoDevNet/webex-contact-center-api-samples"
              className="md-button md-button--28 samples-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('gettingStarted.samplesBtnText')}
            </Link>
          </section>

          <section>
            <h2 id="needHelp">{t('gettingStarted.needHelp')}</h2>
            <div>
              <p>{t('gettingStarted.needHelpP1')} </p>
              <ul>
                <li>
                  {t('gettingStarted.faqTextOne')}
                  <Link
                    to="/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="default-link"
                  >
                    {t('gettingStarted.faq')}
                  </Link>
                  {t('gettingStarted.faqTextTwo')}
                </li>
                <li>
                  {' '}
                  <Link
                    to="https://github.com/CiscoDevNet/webex-contact-center-api-samples"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="default-link"
                  >
                    {t('gettingStarted.samples')} <Icon name="pop-out_12" />{' '}
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://community.cisco.com/t5/contact-center/webex-contact-center-apis-developer-community-and-support/td-p/4558270"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="default-link"
                  >
                    {t('gettingStarted.devCommunityPage')}{' '}
                    <Icon name="pop-out_12" />
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </article>
        <div className="side-panel">
          <TableOfContents tableOfContentsList={tableOfContentsItems} />
        </div>
      </div>
    </DocumentationLayout>
  )
}

export default GettingStarted
